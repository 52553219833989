<template>
  <dx-util-popup
    ref="updateTicketPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    width="800px"
    height="auto"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="updateTicketPopupShown"
    @hidden="updateTicketPopupHidden"
  >
    <div>
      <dx-util-scroll-view width="100%" height="100%">
        <div class="row">
          <div class="col-6 px-1">
            <div class="">
              <dx-util-select-box
                v-model="formData.issueType"
                label="Issue Type"
                class="my-1"
                :items="issueTypes"
              />
              <dx-util-text-box
                v-model="accountNo"
                :visible="isCompanyTypeWarehouse"
                label="Account or Suite No"
                class="my-1"
                @focus-out="onFocusOut"
                @key-down="getCompanyByAccount"
              >
                <dx-text-box-button
                  location="after"
                  name="accountTextBoxButton"
                  :options="accountTextBoxButtonOptions"
                />
              </dx-util-text-box>
              <dx-util-select-box
                v-model="formData.storeId"
                label="Store"
                :data-source="stores"
                display-expr="text"
                value-expr="value"
                class="my-1"
              />
              <dx-util-text-box
                v-model="formData.title"
                label="Title"
                class="my-1"
              />
              <dx-util-text-area
                v-model="formData.description"
                label="Description"
                class="my-1"
                height="200"
                :auto-resize-enabled="true"
              />
            </div>
          </div>
          <div class="col-6 px-1">
            <div class="">
              <dx-util-select-box
                v-model="formData.ticketStatus"
                label="Status"
                class="my-1"
                :data-source="supportTicketStatusOptions"
                display-expr="text"
                value-expr="value"
              />
              <dx-util-select-box
                v-model="formData.assignee"
                label="Assignee"
                class="my-1"
                :data-source="supportStaff"
                display-expr="fullName"
                value-expr="id"
              />
              <dx-util-text-area
                v-model="formData.response"
                label="Response"
                class="my-1"
                height="200"
                :auto-resize-enabled="true"
              />
            </div>
          </div>
        </div>
      </dx-util-scroll-view>
    </div>
    <div class="text-right mt-1 px-1">
      <dx-util-button text="Cancel" type="danger" style="width:120px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Save" type="success" style="width:120px" @click="handleSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import companyService from '@/http/requests/company/companyService'
import { ref, onMounted, reactive } from '@vue/composition-api'
import useCurrentUser from '@/libs/app/current-user'
import { Notify } from '@/@robustshell/utils'
import commonEntityService from '@/http/requests/common/commonEntityService'
import { getSupportTicketStatusList } from '@/enums/supportTicketStatus.enum'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'

export default {
  components: {
    DxTextBoxButton,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    currentTicket: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      supportTicketStatusOptions: getSupportTicketStatusList().filter(el => el.value !== 'ALL'),
      popupTitle: '',
    }
  },
  setup(props) {
    const issueTypes = ref()
    async function getIssueTypes() {
      const result = await companyService.getIssueTypes()
      issueTypes.value = result
    }

    const supportStaff = ref()
    async function getSupportStaff() {
      const result = await companyService.getSupportStaff()
      supportStaff.value = result
    }

    const accountNo = ref('')
    const stores = ref([])

    const formData = reactive({
      id: props.currentTicket.id,
      issueType: props.currentTicket.issueType,
      filePath: props.currentTicket.filePath,
      url: props.currentTicket.url,
      storeId: props.currentTicket.storeId,
      companyId: props.currentTicket.companyId,
      title: props.currentTicket.title,
      description: props.currentTicket.description,
      ticketStatus: props.currentTicket.ticketStatus,
      assignee: '',
      response: '',
    })

    function clearFormData() {
      formData.issueType = ''
      formData.filePath = ''
      formData.storeId = 0
      formData.ticketStatus = ''
      formData.companyId = 0
      formData.url = ''
      formData.title = ''
      formData.description = ''
      formData.response = ''
      formData.assignee = ''
    }

    onMounted(() => {
      getIssueTypes()
      getSupportStaff()
    })

    const { isCompanyTypeWarehouse } = useCurrentUser()
    return {
      issueTypes,
      formData,
      accountNo,
      isCompanyTypeWarehouse,
      stores,
      clearFormData,
      supportStaff,
    }
  },

  computed: {
    updateTicketPopup() {
      return this.$refs.updateTicketPopupRef.instance
    },
    accountTextBoxButtonOptions() {
      return {
        text: 'Get Account',
        type: 'default',
        onClick: () => {
          const accountNo = this.accountNo.replace(/\D/g, '')
          this.getCompanyByAccountNo(accountNo)
        },
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.formData.id = this.currentTicket.id
        this.formData.issueType = this.currentTicket.issueType
        this.formData.filePath = this.currentTicket.filePath
        this.formData.storeId = this.currentTicket.storeId
        this.formData.ticketStatus = this.currentTicket.ticketStatus
        this.formData.companyId = this.currentTicket.companyId
        this.formData.url = this.currentTicket.url
        this.formData.title = this.currentTicket.title
        this.formData.description = this.currentTicket.description
        this.formData.response = this.currentTicket.response
        this.formData.assignee = this.currentTicket.assignee
        this.updateTicketPopup.show()
      },
    },
  },
  mounted() {
    this.getStores()
  },
  methods: {
    updateTicketPopupShown() {
      this.popupTitle = 'Update Support Ticket'
    },
    updateTicketPopupHidden() {
      this.clearFormData()
    },
    closePopup() {
      this.updateTicketPopup.hide()
    },
    async handleSubmit() {
      await companyService.updateTicket(this.formData)
      this.$emit('emit-form-saved')
      this.closePopup()
    },
    onFocusOut(e) {
      const accountNo = e.event.target.value
      if (!accountNo) {
        return
      }
      this.accountNo = accountNo.replace(/\D/g, '')
      this.getCompanyByAccountNo(this.accountNo)
    },
    getCompanyByAccount(e) {
      if (e.event.keyCode !== 13) return
      const accountNo = e.event.target.value
      this.accountNo = accountNo.replace(/\D/g, '')
      this.getCompanyByAccountNo(this.accountNo)
    },
    getCompanyByAccountNo(accountNo) {
      if (!accountNo || accountNo === null || accountNo === 0 || accountNo === '' || accountNo === undefined) {
        Notify.error('Please enter a valid account no')
        return
      }
      companyService.fetchByAccountNo(accountNo).then(result => {
        this.formData.companyId = result.data.id
        this.accountNo = accountNo
        this.getStores()
      })
    },
    getStores() {
      if (this.isCompanyTypeWarehouse && this.accountNo) {
        commonEntityService
          .fetchStoresByParentCompanyAccountNo(this.accountNo)
          .then(result => {
            const { data } = result
            this.setStores(data)
          })
      } else {
        commonEntityService.fetchStoresForCurrentCompany().then(result => {
          const { data } = result
          this.setStores(data)
        })
      }
    },
    setStores(data) {
      this.stores.splice(0, this.stores.length)
      if (data.length > 1) {
        this.stores.push({ value: 0, text: 'Select a store' })
      }
      data.forEach(element => {
        this.stores.push({ value: element.id, text: element.name })
      })
      if (this.stores.length === 1) {
        this.formData.storeId = this.stores[0].value
      }
    },
    uploadFile() {
      this.$refs.fileInput.click()
    },
    async onFileChange(event) {
      const file = event.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      // this.form = formData
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
