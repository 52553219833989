<template>
  <dx-util-popup
    ref="fileDownloadPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    height="auto"
    width="800"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="fileDownloadPopupShown"
    @hidden="fileDownloadPopupHidden"
  >
    <div v-if="componentId">
      <service-folder-file-manager
        ref="refServiceFolderFileManager"
        :company-id="ticket.companyId"
        :upload-document-classes="['NONE_CLASS']"
        :entity-folder-id="ticket.filePath"
        upload-document-class="NONE_CLASS"
        service-folder="SUPPORTTICKET"
        class="px-0"
      />
    </div>
  </dx-util-popup>
</template>

<script>
import ServiceFolderFileManager from '@components/service-folder/ServiceFolderFileManager.vue'
// import { Notify } from '@/@robustshell/utils'

export default {
  components: {
    'service-folder-file-manager': ServiceFolderFileManager,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    ticket: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popupTitle: '',
    }
  },
  computed: {
    fileDownloadPopup() {
      return this.$refs.fileDownloadPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.fileDownloadPopup.show()
      },
    },
  },
  methods: {
    fileDownloadPopupShown() {
      this.popupTitle = 'File Viewer'
    },
    fileDownloadPopupHidden() {
    // Clear form when popup is closed
    },
    closePopup() {
      this.fileDownloadPopup.hide()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
