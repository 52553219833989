<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <dx-data-grid
          id="ticketGrid"
          ref="ticketGridRef"
          height="calc(100vh - 150px)"
          :data-source="TicketDataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @option-changed="onOptionChanged"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing($event)"
          @editor-preparing="onEditorPreparing"
        >
          <template #filterToolbar>
            <div class="d-flex">
              <dx-util-tag-box
                id="store"
                v-model="filters.issueTypes"
                :items="issueTypes"
                class="mr-half flex-grow-1"
                style="min-width: 300px;"
                :max-displayed-tags="2"
                @value-changed="searchByFilter"
              />
              <dx-util-tag-box
                id="store"
                v-model="filters.statuses"
                :data-source="statusOptions"
                display-expr="text"
                value-expr="value"
                style="min-width: 300px;"
                :max-displayed-tags="2"
                class="mr-1"
                @value-changed="searchByFilter"
              />
            </div>
          </template>
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
          <dx-search-panel :visible="true" :width="300" placeholder="Search..." />

          <dx-column data-field="id" caption="Ticket No" width="90" />
          <dx-column data-field="ticketStatus" caption="Status" width="120" cell-template="ticketStatusTemplate" />
          <dx-column data-field="issueType" width="150" />
          <dx-column data-field="title" />
          <dx-column data-field="description" />
          <dx-column data-field="url" />
          <dx-column data-field="userFullName" caption="User" cell-template="userTemplate" width="150" />
          <dx-column :visible="false" data-field="assignAtFullName" caption="Assigned By" cell-template="assignerTemplate" width="150" />
          <dx-column data-field="assigneeFullName" caption="Assignee" cell-template="assigneeTemplate" width="150" />
          <dx-column data-field="tenantId" width="100" />
          <dx-column data-field="companyName" cell-template="companyTemplate" width="180" />
          <dx-column data-field="storeName" cell-template="storeTemplate" width="180" />
          <dx-column data-field="creationTime" caption="Created At" data-type="date" width="100" />
          <dx-column data-field="filePath" caption="File" cell-template="filePathTemplate" width="80" />
          <template #userTemplate="{data}">
            <div>
              <strong class="d-block">
                {{ data.data.userFullName }}
              </strong>
              <small v-if="isAdmin">
                {{ data.data.userId }}
              </small>
            </div>
          </template>
          <template #assignerTemplate="{data}">
            <div>
              <strong class="d-block">
                {{ data.data.assignAtFullName }}
              </strong>
              <small v-if="isAdmin">
                {{ data.data.assignAt }}
              </small>
            </div>
          </template>
          <template #assigneeTemplate="{data}">
            <div>
              <strong class="d-block">
                {{ data.data.assigneeFullName }}
              </strong>
              <small v-if="isAdmin">
                {{ data.data.assignee }}
              </small>
            </div>
          </template>
          <template #companyTemplate="{data}">
            <div>
              <strong class="d-block">
                {{ data.data.companyName }}
              </strong>
              <small v-if="isAdmin">
                {{ data.data.companyId }}
              </small>
            </div>
          </template>
          <template #ticketStatusTemplate="{data}">
            <div :class="resolveStatusVariant(data.value)" class="d-block badge">
              {{ data.value }}
            </div>
          </template>
          <template #storeTemplate="{data}">
            <div>
              <strong class="d-block">
                {{ data.data.storeName }}
              </strong>
              <small v-if="isAdmin">
                {{ data.data.storeId }}
              </small>
            </div>
          </template>
          <template #filePathTemplate="{data}">
            <div v-if="data.value">
              <dx-util-button :id="data.id" text="View" type="success" class="d-block" @click="openFileViewer(data.data)" />
            </div>
          </template>
          <dx-column v-if="isAdmin" caption="Actions" alignment="center" cell-template="itemActions" :width="100" :allow-sorting="false" :fixed="true" fixed-position="left" />
            <template #itemActions="{data}">
              <div class="d-flex">
                <dx-util-button
                  type="warning" icon="bi bi-pencil-square"
                  class="mr-half" hint="Update Ticket"
                  @click="openUpdateTicket(data.data)"
                />
                <dx-util-button
                  type="danger" icon="bi bi-trash"
                  class="mr-half" hint="Delete Ticket"
                  @click="deleteTicket(data.data.id)"
                />
              </div>
            </template>

          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
        </dx-data-grid>
      </div>
    </div>
    <update-ticket :component-id="updateTicketCompId" :current-ticket="selectedTicket" @emit-form-saved="searchByFilter" />
    <file-viewer :component-id="fileViewerCompId" :ticket="selectedTicket" />
  </div>
</template>

<script>
import companyService from '@/http/requests/company/companyService'
import TicketSearchFilter from '@/http/models/search-filters/TicketSearchFilter'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { v4 as uuidv4 } from 'uuid'
import useCurrentUser from '@/libs/app/current-user'
import { getSupportTicketStatusList } from '@/enums/supportTicketStatus.enum'
import { SupportTicketStatusEnum } from '@/enums'
import { TicketDataSource } from './ticketsStore'
import UpdateTicket from './components/UpdateTicket.vue'
import FileViewer from './components/FileViewer.vue'

export default {
  components: {
    UpdateTicket, FileViewer,
  },
  mixins: [GridBase],
  data() {
    return {
      issueTypes: [],
      statusOptions: getSupportTicketStatusList().filter(el => el.value !== 'ALL'),
      TicketDataSource,
      updateTicketCompId: '',
      fileViewerCompId: '',
      selectedTicket: {},
      filters: {
        query: '',
        issueTypes: [],
        statuses: [],
      },
    }
  },
  computed: {
    isAdmin() {
      return this.$can('read', 'resource_admin')
    },
  },
  setup() {
    const { userTenantType } = useCurrentUser()
    return {
      userTenantType,
    }
  },
  created() {
    this.loadData()
    this.getIssueTypes()
  },
  methods: {
    async getIssueTypes() {
      const result = await companyService.getIssueTypes()
      this.issueTypes = result
    },
    loadData() {
      TicketSearchFilter.setDefaultFilters()
      TicketSearchFilter.tenantType = this.userTenantType
      const ticketFilters = TicketSearchFilter.getFilters()
      TicketDataSource.searchValue(ticketFilters)
    },
    openUpdateTicket(ticket) {
      this.selectedTicket = ticket
      this.updateTicketCompId = uuidv4()
    },
    openFileViewer(ticket) {
      this.selectedTicket = ticket
      this.fileViewerCompId = uuidv4()
    },
    async deleteTicket(id) {
      this.$swal({
        title: 'Please confirm that you want to delete this ticket?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-warning ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          companyService.deleteTicket(id).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'The ticket has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            TicketDataSource.reload()
          })
        }
      })
    },
    searchByFilter() {
      TicketSearchFilter.issueTypes = this.filters.issueTypes
      TicketSearchFilter.ticketStatuses = this.filters.statuses
      TicketSearchFilter.q = this.filters.query
      const ticketFilters = TicketSearchFilter.getFilters()
      TicketDataSource.searchValue(ticketFilters)
      TicketDataSource.reload()
    },
    resolveStatusVariant(status) {
      if (status === SupportTicketStatusEnum.OPEN.value) return 'bg-danger'
      if (status === SupportTicketStatusEnum.WORKING.value) return 'bg-warning'
      if (status === SupportTicketStatusEnum.RESOLVED.value) return 'bg-success'
      if (status === SupportTicketStatusEnum.PLANNED.value) return 'bg-info'
      return 'bg-secondary'
    },
    onOptionChanged(e) {
      if (e.fullName === 'searchPanel.text') {
        this.filters.query = ''
        this.filters.query = e.value
      }
    },
    onEditorPreparing(e) {
      if (e.parentType !== 'searchPanel') return
      e.editorOptions.onValueChanged = arg => {
        const query = arg.component.option('value')
        if (!query) {
          this.searchQuery = query.trim()
          e.component.searchByText('')
          this.searchByFilter()
        }
      }
      e.editorOptions.onKeyDown = arg => {
        if (arg.event.keyCode === 13) {
          let query = arg.component.option('value')
          query = query.trim()
          arg.component.option('value', query)
          this.searchQuery = query
          this.searchByFilter()
          e.component.searchByText(query)
        }
        if (arg.event.keyCode === 27) {
          e.component.searchByText('')
          arg.component.option('value', '')
          this.searchQuery = ''
          this.searchByFilter()
        }
      }
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            TicketDataSource.reload()
          },
        },
        location: 'after',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
