/* eslint-disable lines-between-class-members */
import BaseSearchFilter from './BaseSearchFilter'

export class TicketFilter extends BaseSearchFilter {
  /** @type {string} */
  #assignee = ''
  #assignAt = ''
  #userId = ''
  #issueTypes = []
  #ticketStatuses = []
  constructor() {
    super()
    this.#assignee = this.assignee
    this.#assignAt = this.assignAt
    this.#userId = this.userId
    this.#issueTypes = this.issueTypes
    this.#ticketStatuses = this.ticketStatuses
  }

  setDefaultFilters() {
    this.accountNo = ''
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.q = ''
    this.assignee = ''
    this.assignAt = ''
    this.userId = ''
    this.issueTypes = []
    this.ticketStatuses = []
  }

  /** @returns {Object} filter object */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      q: this.q,
      assignee: this.assignee,
      assignAt: this.assignAt,
      userId: this.userId,
      issueTypes: this.issueTypes,
      ticketStatuses: this.ticketStatuses,
    }
    return filter
  }

  /** @returns {string} */
  get assignee() {
    return this.#assignee
  }
  /** @param {string} value */
  set assignee(value) {
    this.#assignee = value
  }
  /** @returns {string} */
  get assignAt() {
    return this.#assignAt
  }
  /** @param {string} value */
  set assignAt(value) {
    this.#assignAt = value
  }
  /** @returns {string} */
  get userId() {
    return this.#userId
  }
  /** @param {string} value */
  set userId(value) {
    this.#userId = value
  }
  /** @returns {array} */
  get issueTypes() {
    return this.#issueTypes
  }
  /** @param {array} value */
  set issueTypes(value) {
    this.#issueTypes = value
  }
  /** @returns {array} */
  get ticketStatuses() {
    return this.#ticketStatuses
  }
  /** @param {array} value */
  set ticketStatuses(value) {
    this.#ticketStatuses = value
  }
}
export default new TicketFilter()
