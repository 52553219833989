/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import companyService from '@/http/requests/company/companyService'
import Pager from '@core/dev-extreme/utils/pager'

const dataStore = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : [{ selector: 'id', desc: false }]
    const pager = new Pager(options)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    let sortOrder = ''
    if (sort) {
      pager.setSortQuery(sort)
      sortOrder = `&sort=${pager.toCreateSortOrder()}`
    }

    const pageableQuery = `${pager.toCreatePageable()}${sortOrder}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    const response = await companyService.queryTickets(filters, pageableQuery)
    const data = response
    return {
      data: data.content,
      totalCount: data.totalElements,
    }
  },
})

const ticketDataSource = new DataSource({
  store: dataStore,
})

export {
  ticketDataSource as TicketDataSource,
}
